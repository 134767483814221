import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { ButtonGroup, LoadableButton } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "loadablebutton"
    }}>{`LoadableButton`}</h1>
    <p>{`Button with a loading state`}</p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<ButtonGroup mb=\"sm\">\n  <LoadableButton loading={true}>Loading</LoadableButton>\n  <LoadableButton loading={false}>Not loading</LoadableButton>\n</ButtonGroup>\n<ButtonGroup>\n  <LoadableButton loading={true} small>\n    Small loading\n  </LoadableButton>\n  <LoadableButton loading={false} small>\n    Small not loading\n  </LoadableButton>\n</ButtonGroup>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      ButtonGroup,
      LoadableButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ButtonGroup mb="sm" mdxType="ButtonGroup">
    <LoadableButton loading={true} mdxType="LoadableButton">Loading</LoadableButton>
    <LoadableButton loading={false} mdxType="LoadableButton">Not loading</LoadableButton>
  </ButtonGroup>
  <ButtonGroup mdxType="ButtonGroup">
    <LoadableButton loading={true} small mdxType="LoadableButton">
      Small loading
    </LoadableButton>
    <LoadableButton loading={false} small mdxType="LoadableButton">
      Small not loading
    </LoadableButton>
  </ButtonGroup>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={LoadableButton} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      